import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router'
import {createMyI18n} from "./utils/i18n";
require('@/assets/main.scss');

const thisrouter = router;
const app = createApp(App);
app.use(store).use(thisrouter).use(createMyI18n());
app.mount('#app');