<template>
  <div class="privacy_wrapper">
      <div id="private_start"></div>
    <MyHeader :with_login="false" style="flex: 1 0 auto" />

    <div class="bg7 bg">
      <div id="private_image_div" class="privacy_column">
        
        <div class="text_column">
          <h1 class="mg_title">Mind the privacy</h1>
          <div class="mg_description">
            <p>
             {{$t("privacy_page_text_1")}}
            </p><br>
            <p>
             {{$t("privacy_page_text_2")}}
            </p>
          </div>
        </div>
        <div id="private_image_id" class="anim_img">
          <img src="@/assets/images/armourMG_smaller.png" alt="android" />
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script>
import MyHeader from "@/components/parts/MyHeader.vue";
import MyFooter from "@/components/parts/MyFooter.vue";
import { onBeforeMount } from "vue";
export default {
  components: {
    MyHeader,
    MyFooter,
  },
  setup() {
     /*  function scrollToStart() {
      let el = document.getElementById("private_start");
      el.scrollIntoView({ behavior: "smooth" });
    } */
    onBeforeMount(()=> window.scrollTo({ top: 0, behavior: 'smooth' }));
  },
};
</script>
<style scoped>
.privacy_wrapper {
  height: 100%;
}
.privacy_column{
    display: grid;
    grid-template-columns: 3fr 2fr;
}
.bg7 {
  background-image: url("~@/assets/images/drawing6.svg");
  min-height: 85%;
  padding-top: 2em;
    padding-bottom: 2em;

}

.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}


@keyframes android {
  from {
    right: -150px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.privacy_link {
  font-weight: 700;
  color: cyan;
}


@media only screen and (max-width: 400px) {
   .privacy_column{
       display: block;
   }
   .anim_img{
     padding-top:1em;
   }
  }
</style>