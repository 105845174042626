<template>
    <div>
        {{ timeString }}
    </div>
</template>
<script>
import { ref, onBeforeMount } from "vue";
export default {
    props:{
        startTime:{
            type:String,
            required:true,
        }
    },
    setup(props) {
        const timeString = ref("");
        onBeforeMount(() => {
            makeTimer();
        });

        function makeTimer() {
/**
 * 
Taken for granted that time comes in form of '2024-01-23 01:00', so with a space in it and no T.

 */

            let countDownDate = new Date(props.startTime.replace(/\s/, 'T')).getTime();
            let x = setInterval(function () {
                let nowTime = new Date().getTime();
                let timeDist = countDownDate - nowTime;
                let dd = Math.floor(timeDist / (1000 * 60 * 60 * 24));
                let hh = Math.floor((timeDist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let mm = Math.floor((timeDist % (1000 * 60 * 60)) / (1000 * 60));
                let sec = Math.floor((timeDist % (1000 * 60)) / 1000);
                timeString.value = dd.toString() + "d " + hh.toString() + "h "
                    + mm.toString() + "m " + sec.toString() + "s ";

                // If the count down is over, write some text 
                if (timeDist < 0) {
                    clearInterval(x);
                    timeString.value = "Contest expired";
                }
            }, 1000);
        }

        return {
            timeString
        };
    }
}
</script>