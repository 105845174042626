<template>
  <div class="bg4 bg">
    <div id="flow_start"></div>
    <div id="flow_image_div" class="title_image">
      <div>
        <div id="flow_image_id" class="anim_img_invisible">
          <img src="@/assets/images/yin_yang.png" alt="yin yang" />
        </div>
        <div class="marquee_wrapper">
          <p class="marquee">
            <span
              >&emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; {{$t("flow_scroll_text")}}</span
            >
          </p>
        </div>
      </div>
      <div class="down_arrow">
        <i class="fas fa-comment" @click="scrollToNext"></i>
      </div>
      <div class="text_column">
        <h1 class="mg_subtitle">
          {{$t("how_do_you")}} <span class="mg_title">{{$t("communicate")}}</span>
        </h1>
        <div class="mg_description">
          <p>
           {{$t("flow_text_1")}}
          </p>
          <p>
            {{$t("flow_text_2")}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from "vue";
export default {
  setup() {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    function callbackFunc(entries, observer) {
      count += 1;
      if (count == 2) {
        let image = document.getElementById("flow_image_id");
        image.classList.remove("anim_img_invisible");
        image.classList.add("anim_img");
      }
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(callbackFunc, options);

    onBeforeMount(() => {
      setTimeout(function () {
        let elem = document.getElementById("flow_image_div");
        observer.observe(elem);
      }, 100);
    });
    function scrollToNext() {
      let el = document.getElementById("open_start");
      el.scrollIntoView({ behavior: "smooth" });
    }

    return {
      scrollToNext,
    };
  },
};
</script>
<style scoped>
.bg4 {
  background-image: url("~@/assets/images/drawing4.svg");
  height: 100%;
}
.marquee span {
      animation: marquee 50s linear infinite;
  }
.mg_title {
  font-size: 1.7em;
}
.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}
.anim_img_invisible {
  visibility: hidden;
}

@keyframes android {
  from {
    left: -150px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 400px) {
  .bg4 {
    display: block;
    overflow: auto;
  }
  .anim_img {
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .text_column {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}
</style>