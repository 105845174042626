<template>
    <div class="backblack p-4">
        <div class="has-text-weight-bold">
            {{$t("terms_of_service")}}
        </div>
        <div class="has-text-justified">
      <p>  {{$t("terms_text")}}</p>
        </div>
    </div>
</template>
<style scoped>
.backblack{
    color: white;
    background-color: black;
}
</style>