<template>
  <div class="bg3 bg">
    <div id="people_start"></div>
    <div id="people_image_div" class="title_image">
      <div class="text_column">
        <h1 class="mg_subtitle">
          {{ $t("what_is") }}
          <span class="mg_title">{{ $t("imaginary") }}</span>
          {{ $t("local_communication") }}
        </h1>
        <div class="mg_description">
          <p>
            {{ $t("imaginary_text_1") }}
          </p>
          <p>
            {{ $t("imaginary_text_2") }}
          </p>
        </div>
        <!-- <div class="mg_subtitle pt-2">{{ $t("examples") }}</div>
        <div>
          <router-link to="/dentist" class="hot_link">{{ $t("dental_clinic") }}</router-link>
        </div> -->
      </div>
      <div class="down_arrow">
        <i class="fas fa-map-marked" @click="scrollToNext"></i>
      </div>
      <div>
        <div id="my_room_image_id" class="anim_img_invisible">
          <img src="@/assets/images/messy_room.png" alt="my room map" />
        </div>
        <!--   We stay friends, we build things together, we nourish all these
          precious relations we have by being who we are as persons, not by
          looking in one way or another. A picture of us can be a starting point
          (and that’s probably the reason why we think it is so important) but
          is no foundation to any relation. Mindglue tries to bring forward what
          that foundation can be. -->
        <div class="marquee_wrapper">
          <p class="marquee">
            <span>&emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              {{ $t("imaginary_scroll_text") }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from "vue";
export default {
  setup() {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    function callbackFunc(entries, observer) {
      count += 1;
      if (count == 2) {
        let image = document.getElementById("my_room_image_id");
        image.classList.remove("anim_img_invisible");
        image.classList.add("anim_img");
      }
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(callbackFunc, options);

    onBeforeMount(() => {
      setTimeout(function () {
        let elem = document.getElementById("my_room_image_id");
        observer.observe(elem);
      }, 100);
    });
    function scrollToNext() {
      let el = document.getElementById("flow_start");
      el.scrollIntoView({ behavior: "smooth" });
    }

    return {
      scrollToNext,
    };
  },
};
</script>
<style scoped>
.bg3 {
  background-image: url("~@/assets/images/drawing3.svg");
  height: 100%;
}

.marquee span {
  animation: marquee 25s linear infinite;
}

.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}

.anim_img_invisible {
  visibility: hidden;
}

@keyframes android {
  from {
    right: -150px;
    opacity: 0;
  }

  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 400px) {
  .bg3 {
    display: block;
    overflow: auto;
  }

  .anim_img {
    padding-bottom: 1em;
    padding-top: 1em;
  }

  .text_column {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}</style>