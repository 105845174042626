<template>
    <div class="backblack p-4">
        <div>Mindglue UG (haftungsbeschränkt)</div>
        <div>Stuttgarter Str. 106</div>
        <div>70736, Fellbach</div>
        <div class="more_data">
            <div class="more_data_row">Telefon</div>
            <div class="more_data_row">+49 17621484453</div>
        </div>
         <div class="more_data">
            <div class="more_data_row">Email</div>
            <div class="more_data_row">info@mindglue.io</div>
        </div>
         <div class="more_data">
            <div class="more_data_row">Website</div>
            <div class="more_data_row">mindglue.io</div>
        </div>
         <div class="more_data">
            <div class="more_data_row">Registergericht</div>
            <div class="more_data_row">Amtsgericht Nürnberg</div>
        </div>
         <div class="more_data">
            <div class="more_data_row">Registernummer</div>
            <div class="more_data_row">HRB 40358</div>
        </div>
        <div class="more_data">
            <div class="more_data_row">Vertretungsberechtigter Geschäftsführer</div>
            <div class="more_data_row">c/o Block Services</div>
        </div>
         <div class="more_data">
            <div class="more_data_row">Umsatzsteuer-Identifikationsnummer gemäß § 27 UStG</div>
            <div class="more_data_row">241/132/52002</div>
        </div>
    </div>
</template>
<style scoped>
.more_data{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.more_data_row{
    text-align: left;
}
.backblack{
    color: white;
    background-color: black;
}
</style>