import QuesdomIntro from '@/components/pages/QuesdomIntro.vue'
import MyHome from '@/components/pages/MyHome.vue'
import MyPrivacy from '@/components/pages/MyPrivacy.vue'
import DeleteAccount from '@/components/pages/DeleteAccount.vue'
import DeleteContent from '@/components/pages/DeleteContent.vue'
import MindglueCompany from '@/components/pages/MindglueCompany.vue'
import DentalClinic from '@/components/pages/DentalClinic.vue'
import MoreInfo from '@/components/pages/MoreInfo.vue'
import CurrentCampaign from '@/components/pages/CurrentCampaign.vue'
import LegacyOfLostLove from '@/components/campaigns/LegacyOfLostLove.vue'
import EmptyRouterView from '@/components/parts/EmptyRouterView.vue'


export default [
  {
    path: '/:lang(de|fr|en|zu)?',
    component:  EmptyRouterView,
    children: [{
      path: 'whatis',
      component: MyHome,
    },
    {
     path: '',
      component: QuesdomIntro,
      /* path: '',
      component: CurrentCampaign,
      children: [
        {
          path: '',
          component: LegacyOfLostLove,
  
        },
        
      ] */
    },
    {
      path: 'privacy',
      component: MyPrivacy,
    },
    {
      path: 'delete_account',
      component: DeleteAccount,
    },
    {
      path: 'delete_content',
      component: DeleteContent,
    },
    {
      path: 'mindglue_company',
      component: MindglueCompany,
    },
    {
      path: 'dentist',
      component: DentalClinic,
    },
    {
      path: 'more/:work',
      component: MoreInfo,
    },
    {
      path: 'current_campaign',
      component: CurrentCampaign,
      children: [
        {
          path: 'legacy_of_lost_love',
          component: LegacyOfLostLove,
  
        },
        
      ]
    },
    ]
  }
];

