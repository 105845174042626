<template>
  <div class="home">
    <MyHeader :with_login="false" style="flex: 1 0 auto" />
    <FH90Intro />
    <FH100Local />
    <FH100Imaginary />
    <FH100Flow />
    <FH100Open />
    <FH100Private />
    <FH95Come />
    <div class="bg2 bg"></div>
    <MyFooter />
  </div>
</template>
<script>
import FH95Come from "@/components/parts/FH95Come.vue";
import FH100Private from "@/components/parts/FH100Private.vue";
import FH100Open from "@/components/parts/FH100Open.vue";
import FH100Flow from "@/components/parts/FH100Flow.vue";
import FH100Imaginary from "@/components/parts/FH100Imaginary.vue";
import FH100Local from "@/components/parts/FH100Local.vue";
import FH90Intro from "@/components/parts/FH90Intro.vue";
import MyHeader from "@/components/parts/MyHeader.vue";
import MyFooter from "@/components/parts/MyFooter.vue";
export default {
  components: {
    MyHeader,
    MyFooter,
    FH90Intro,
    FH100Local,
    FH100Imaginary,
    FH100Flow,
    FH100Open,
    FH100Private,
    FH95Come,
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}

</style>
