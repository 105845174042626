import routes from '@/router/routes.js'
import { createRouter, createWebHistory } from 'vue-router'
//import store from '../store';

const router = createRouter({
    history: createWebHistory(),
    routes: routes,

});

/* router.beforeEach((to) => {
    let isLoggedIn = store.getters["core/isLoggedIn"];
    if (to.meta.requiresAuth && !isLoggedIn) {
        return {
            path: '/login',
            query: { redirect: to.fullPath },
        }
    }
    if (to.path==='/login') {
       store.commit('core/renewLog');
    }
})

router.beforeEach((to, from) => {
    if (to.path == "/createEditMgq" && from.path == "/mgq_attributes"){
        store.commit('core/renewAfterCreate'); 
        return "/dashboard";  
    }
  }) */

export default router;



