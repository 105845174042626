<template>
  <div class="mindglue_comp_wrapper">
    <MyHeader :with_login="false" style="flex: 1 0 auto" />

    <div class="bg7 bg">
      <div id="private_image_div" class="privacy_column">
        <div class="anim_img1">
          <img src="@/assets/mindglue_logo.svg" alt="logo mindglue" />
        </div>
        <div class="text_column privacy_text_column">
          <h1 class="mg_title">{{$t("who_we_are")}}</h1>
          <div class="mg_description">
            {{$t("mg_company")}}
          </div>
          <br>
          <div class="comp_link"
            @click="
              selectedComponent = 'TermsOfService';
              showModal = !showModal;
            "
          >
            {{$t("terms_of_service")}}
          </div>
           <div class="comp_link"
            @click="
              selectedComponent = 'MyImpressum';
              showModal = !showModal;
            "
          >
            {{$t("impressum")}}
          </div>
          <div class="comp_link"
            @click="
              selectedComponent = 'ContactForm';
              showModal = !showModal;
            "
          >
            {{$t("contact_us")}}
          </div>
        </div>
        <div class="anim_img2">
          <img src="@/assets/mindgluequest_logo.svg" alt="logo mindgluequest" />
        </div>
      </div>
    </div>
    <MyFooter />
    <div v-if="showModal">
      <MyModal @close="closeModal" @confirm="closeModal"
        ><component :is="selectedComponent"
      /></MyModal>
    </div>
  </div>
</template>
<script>
import TermsOfService from "@/components/parts/TermsOfService.vue";
import MyImpressum from "@/components/parts/MyImpressum.vue";
import MyModal from "@/components/parts/MyModal.vue";
import MyHeader from "@/components/parts/MyHeader.vue";
import MyFooter from "@/components/parts/MyFooter.vue";
import ContactForm from "@/components/parts/ContactForm.vue";
import { onBeforeMount, ref } from "vue";
export default {
  components: {
    MyHeader,
    MyModal,
    MyFooter,
    TermsOfService,
    MyImpressum,
    ContactForm,
  },
  setup() {
    const showModal = ref(false);
    const selectedComponent = ref("");
    function closeModal() {
      showModal.value = false;
    }
    onBeforeMount(() => window.scrollTo({ top: 0, behavior: "smooth" }));
    return {
      closeModal,
      selectedComponent,
      showModal,
    };
  },
};
</script>
<style scoped>
.mindglue_comp_wrapper {
  height: 100%;
}
.privacy_column {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}
.comp_link{
  color:white;
  font-weight:700;
  cursor: pointer;
}
.bg7 {
  background-image: url("~@/assets/images/drawing7.svg");
  min-height: 85%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.anim_img1 {
  position: relative;
  animation-name: compAnim1;
  animation-duration: 3s;
  animation-timing-function: ease;
}
.anim_img2 {
  position: relative;
  animation-name: compAnim2;
  animation-duration: 3s;
  animation-timing-function: ease;
}

@keyframes compAnim1 {
  from {
    left: -150px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}
@keyframes compAnim2 {
  from {
    right: -150px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.privacy_link {
  font-weight: 700;
  color: cyan;
}

@media only screen and (max-width: 400px) {
  .privacy_column {
    display: block;
  }
}
</style>