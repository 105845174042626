<template>
  <div class="header_grid">
    <div id="header_start" >
      <div class="logo" @click="goToStart">
        <div style="cursor:pointer;" class="p-2">
          <img src="@/assets/mindglue_logo.svg" width="40" />
        </div>
        <div class="mindglue_letters" style="cursor:pointer;">mindglue</div>
      </div>
      
    </div>
    <div class="lang_slugs">
        <a @click="setLang('en')">en</a
        ><a class="pl-2" @click="setLang('de')">de</a>
      </div>
      <div v-if="with_login">
        <!--   <LogButton /> -->
      </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  props: {
    with_login: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // LogButton,
  },
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    function goToStart() {
      router.push("/");
    }

    function setLang(thislang) {
      i18n.locale.value = thislang
      const to = router.resolve({ params: { lang: thislang } });
      router.push(to);
    }

    return {
      goToStart,
      setLang,
    };
  },
};
</script>

<style scoped>
.header_grid {
  display: grid;
  grid-template-columns: 7fr 1fr;
  background-color: #e6f9ff;
  align-items: center;
 height: 10%;
}
.logo {
  display: flex;
  margin-left: 20px;
  align-items: center;

}

.mindglue_letters {
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.6rem;
  color: #2c9efc;
  margin-left: 10px;
}
.quest_letters {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  color: #0072cf;
}
.lang_slugs {
  display: flex;
}
</style>