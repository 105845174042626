<template>
  <router-view />
</template>
<script>
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    onBeforeMount(() => {
      if (router.currentRoute.value.params.lang != "") {
        console.log("locale " + router.currentRoute.value.params.lang);
        i18n.locale.value = router.currentRoute.value.params.lang;
      } else {
        i18n.locale.value = "en";
      }
    });
    return {};
  },
};
</script>