import { createStore,     createLogger   } from 'vuex';
import core from '@/store/core';


export default createStore({

    modules: {
        core: core,
    },
    plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
  })