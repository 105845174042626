<template>
  <div class="contact_form">
    <div class="field">
      <label class="label">Email</label>
      <input type="text" v-model="email" placeholder="me@memail.com" class="input" @change="wrongEmail = false" />
    </div>
    <div class="field">
      <label class="label">Name</label>
      <input type="text" v-model="name" placeholder="Firstname Lastname" class="input" />
    </div>
    <div class="field">
      <label class="label">What I do</label>
      <div class="select">
        <select class="select" v-model="workHere">
          <option value="visual_arts">Visual arts creator</option>
          <option value="data_analyst">Data analyst</option>
          <option value="adventure_organizer">Adventure organizer</option>
          <option value="tourist_guide">Tourist guide</option>
          <option value="engineer">Engineer</option>
          <option value="influencer">Influencer</option>
          <option value="venue_planner">Venue planner</option>
          <option value="content_creator">Content creator</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>
    <div class="field">
      <label class="label">How can we help you</label>
      <textarea class="textarea" placeholder="Description" rows="2" v-model="description"></textarea>
    </div>
    <div v-if="wrongEmail">
      The email address you provided does not seem right.
    </div>
    <div class="button is-primary" @click="send">Send</div>
    <br /><br />
  </div>
</template>
<script>
import { ref, toRefs } from "vue";

export default {
  props: {
    work: {
      type: String,
      default: "",
    }
  },
  setup(props, { emit }) {
    const email = ref("");
    const name = ref("");
    const workHere = toRefs(props).work;
    const description = ref("");
    const wrongEmail = ref(false);
    function validateEmail() {
      let found = String(email.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (null == found) return false;
      else return true;
    }

    async function send() {
      if (validateEmail()) {
        let contentAnswer = await fetch(
          "https://application.mindglue.io/App/mgq/MglSiteEmailer",
          //"http://127.0.0.1:8080/App/mgq/MglSiteEmailer",
          {
            method: 'POST',
            cors: 'no-cors',
            body: JSON.stringify({
              email: email.value,
              name: name.value,
              work: workHere.value,
              description: description.value,
            }),
          }
        );
        let answer = await contentAnswer.text();
        if (answer == "ok!") {
          alert("Message sent! Our answer won't take long!")
          emit("closeContact");
        }
        else {
          alert("Something went wrong. Please try again later...")
          emit("closeContact");
        }
      } else {
        wrongEmail.value = true;
      }
    }
    return {
      email,
      name,
      workHere,
      description,
      wrongEmail,
      send,
    };
  },
};
</script>
<style>
.contact_form {
  opacity: 1;
  z-index: 55;
  padding:1em;
}
</style>