<template>
    <div class="camp_wrapper">
        <div class="camp_box_emphasis camp_3d_left mt-4">
            <div class="camp_title big"><a style="color: white;" href="https://mindgluequest.com/showSelected/248">The
                    Legacy of
                    Lost
                    Love</a>
            </div>
        </div>
        <div class="mt-6 mb-6">
            <div><img width="200" class="rounded_corners" src="@/assets/images/lost_love_cover.png" />
                <div class="camp_descr big seventy"><a style="color: white;"
                        href="https://www.mindglueapp.com/#/mindgluequest/248">Are you
                        ready for a captivating online adventure that
                        blends mystery, romance, and the thrill of exploration? Join our
                        immersive adventure, "The Legacy of Lost Love," and uncover the secrets of a
                        heart-wrenching
                        mystery that spans enchanting destinations!
                    </a></div>
            </div>
        </div>
        <div class="camp_descr">
            <div class="video_flutter">
                <div class="mt-4"><iframe width="300px" height="530" src="https://youtube.com/embed/qihPlj4mvuw"></iframe>
                </div>
                <div><iframe src="https://mindglueapp.com/#/mindgluequest/248" width="340" height="540"></iframe></div>
            </div>
        </div>
        <div class="camp_box_emphasis camp_3d_right mt-6 ">
            <div class="camp_title ">🏆 $50 giftcard</div>
            <div class="camp_descr big">Giveaway $50 Amazon giftcard for the user that achieves the highest success
                grade first!
            </div>
        </div>
        <div class=" camp_descr small seventy mt-4">
            <ol style="text-align: left;">
                <li>To claim the giftcard users must have logged in with a valid email adress. A generic (automatic-created)
                    account cannot be used.
                </li>
                <li>Start and end of the contest are determined in the specific mindgluequest description like every
                    mindgluequest and all the terms and conditions of mindgluequests are in this case also valid.
                </li>
            </ol>
        </div>
        <div class="mt-6">
            <div style="color:white; font-size: xx-large;">Contest starts in</div>
            <CountdownTimer class="countdown_timer" :startTime="'2024-01-24 02:00'" />
        </div>
        <div class="camp_box_emphasis camp_3d_left mt-6 mb-2">
            <div class="camp_descr big">What is this thing?</div>
            <div class="camp_descr">Is it for me?</div>
        </div>
        <iframe width="300px" height="530" src="https://youtube.com/embed/Vh1UtmZ-Jmw?si=tGL4bGziy8WwfOLB"></iframe>
        <div class="camp_descr seventy mt-4">If you want to try a mindgluequest before joining The Legacy of Lost Love, you
            can try the other contest-mindgluequests
            like <a style="color: orange;" href="https://mindgluequest.com/showselected/242">Hoptons' Mystery</a>,
            <a style="color: palegreen;" href="https://mindgluequest.com/showselected/84">Chaotique</a>, or even the
            <a style="color: lightblue;" href="https://mindgluequest.com/showselected/278">Example</a> displayed in the
            video.
            Tap on the play button top left or the web app banner or, for a better experience, download the mobile app and
            find them on the mindgluequests tab.
            Of course, you can make your own mindgluequest as well!
        </div>
    </div>
</template>
<script>
import CountdownTimer from '@/components/parts/CountdownTimer.vue';

export default {
    components: {
        CountdownTimer
    },
}
</script>
<style scoped>
.countdown_timer {
    color: white;
    text-shadow: 3px 3px 5px purple;
    font-size: 5em;
}

.video_flutter {
    display: flex;
    justify-content: center;
    gap: 2em;
    align-items: baseline;
}

.img_logo {
    width: 1.5em;
}

@media only screen and (max-width: 400px) {
    .video_flutter{
        display: block;
        margin-top: 2em;
        margin-bottom: 5em;
    }
}
</style>