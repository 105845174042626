<template>
  <div class="bg6 bg">
    <div id="private_start"></div>
    <div id="private_image_div" class="title_image">
      <div id="private_image_id" class="anim_img_invisible">
        <img src="@/assets/images/knightA.png" alt="knight" />
      </div>

      <div class="down_arrow">
        <i class="fas fa-key" @click="scrollToNext"></i>
      </div>
      <div class="text_column">
        <h1 class="mg_subtitle"><span class="mg_title">{{$t("private")}}</span><br>{{$t("not_only_in_privacy")}}</h1>
        <div class="mg_description">
          <p>{{$t("privacy_text_1")}}</p>
          <p>{{$t("privacy_text_2")}}</p>
        </div>
        <div><router-link to="/privacy" class="hot_link">{{$t("can_check_privacy")}}</router-link></div>
      </div>
    </div>  
  </div>
</template>
<script>
import { onBeforeMount } from "vue";

export default {
  setup() {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    function callbackFunc(entries, observer) {
      count += 1;
      if (count == 2) {
        let image = document.getElementById("private_image_id");
        image.classList.remove("anim_img_invisible");
        image.classList.add("anim_img");
      }
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(callbackFunc, options);

    onBeforeMount(() => {
      setTimeout(function () {
        let elem = document.getElementById("private_image_div");
        observer.observe(elem);
      }, 100);
    });
    function scrollToNext() {
      let el = document.getElementById("come_start");
      el.scrollIntoView({ behavior: "smooth" });
    }

    return {
      scrollToNext,
    };
  },
};
</script>
<style scoped>
.bg6 {
  background-image: url("~@/assets/images/drawing6.svg");
  height: 100%;
}

.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}
.anim_img_invisible {
  visibility: hidden;
}

@keyframes android {
  from {
    left: -150px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



@media only screen and (max-width: 400px) {
  .bg6 {
    display: block;
    overflow: auto;
  }
  .anim_img {
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .text_column {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}
</style>