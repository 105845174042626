<template>
    <div>
        <MyHeader />

        <div class="grad_back">
            <div>
                <div class="pt-6 pb-4 is-size-4">
                    {{ $t(workHere + "_title") }}
                </div>
                <div v-for="(txt, idx) in textBody" :key="idx" class="pr-6 pl-6">
                    <div class="text_body m-2">{{ txt }}</div>
                </div>
                <br>
                <div v-if="mindglueappUrl != ''">
                    <a :href="mindglueappUrl" class="is-size-5">Enter Mindgluequest Example</a>
                </div>
                <br>
                <div v-if="youtubeUrl != ''" class="mt-2">
                    <iframe :width="videoWidth" :height="videoHeight" :src="youtubeUrl" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="p-2 pl-6 pr-6 contact_wrap">
                <div class="m-6 p-6 contact_form" :style="'min-width:'+videoWidth+'px;'">
                    <div class="title">
                        Contact us!
                    </div>
                    <ContactForm @closeContact="contactClosed = true" :work="workHere" />
                </div>
            </div>
        </div>
        <MyFooter />

    </div>
</template>
<script>
import { ref, computed, } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import MyFooter from "@/components/parts/MyFooter.vue";
import MyHeader from "@/components/parts/MyHeader.vue";
import ContactForm from "@/components/parts/ContactForm.vue";
import * as mixins from "@/utils/mixins";

export default {
    components: {
        MyHeader,
        ContactForm,
        MyFooter,
    },
    setup() {
        const i18n = useI18n();
        const router = useRouter();
        const workHere = ref(router.currentRoute.value.params.work);
        const videoHeight = computed(
            () =>
                (textBody.value.length > 2
                    ? mixins.screenHeight.value * 0.3
                    : mixins.screenHeight.value * 0.4) | 0
        );
        const videoWidth = mixins.videoWidth;
        const youtubeUrl = computed(() => i18n.t(workHere.value + "_youtube_url"));
        const textBody = computed(() => i18n.t(workHere.value + "_body").split("\n"));
        const mindglueappUrl = computed(() =>
            i18n.t(workHere.value + "_mindglueapp_url")
        );
        return {
            workHere,
            youtubeUrl,
            mindglueappUrl,
            textBody,
            videoHeight,
            videoWidth,
        }
    }
}
</script>
<style>
.contact_form {
    background-color: rgb(29, 26, 26);
    border-radius: 1%;
    max-width: 900px;
}
.grad_back {
  background-image: linear-gradient(
    to bottom right,
    rgb(59, 0, 155),
    rgb(138, 0, 143)
  );
  color:white;
  height: 100%;
}
.contact_wrap{
    display: flex;
    justify-content: center;
}
</style>