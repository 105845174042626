<template>
  <div class="qd_wrapper">
    <!--    <MyHeader :with_login="false" style="flex: 1 0 auto" /> -->

    <div class="bg7 bg">
      <div>
        <div class="text_column ">
          <h1 class="mg_title mb-6"> Mindglue proudly presents you <a class="link_here" href="https://quesdom.com">Quesdom</a></h1>
        </div>
        <div class="qd_column">
          <div class="p-4"></div>
          <div class="qd card m-2 skew-on-hover skew_right">
            <div class="qd_subtitle"> {{ $t("quesdom_subtitle1") }}</div>
            <div class="qd_subtitle_icon" style="color:orange;">  <img src="@/assets/quesdom_leaves.svg" alt="quesdom" id="svvgg" width="80"/></div>
            {{ $t("quesdom_text1") }}
          </div>
          <div class="p-4"></div>
        </div>
        <div class="qd_column">
          <div class="qd card m-2 skew-on-hover skew_left">
            <div class="qd_subtitle"> {{ $t("quesdom_subtitle2") }}</div>
            <div class="qd_subtitle_icon"  style="color:purple;"> <i class="fas fa-spray-can"></i></div>
            {{ $t("quesdom_text2") }}
          </div>
          <div class="video_div">
            <iframe width="560" height="315" src="https://youtube.com/embed/oFXnAomFGpI?si=mhyeQIBS_nJ6gdUh"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/OjeJvNNiKr4?si=X7ByBvsgqV_YontB"
              title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div class="qd card m-2 skew-on-hover skew_right">
            <div class="qd_subtitle"> {{ $t("quesdom_subtitle3") }}</div>
            <div class="qd_subtitle_icon"  style="color:green;"> <i class="fas fa-birthday-cake"></i></div>
            {{ $t("quesdom_text3") }}
          </div>
        </div>
        <div class="qd_column" style="cursor: pointer;" @click="goToQuesdom">
          <div class="p-4"></div>
          <div class="qd card m-2 skew-on-hover skew_left">
            <div class="qd_subtitle"> {{ $t("quesdom_subtitle4") }}</div>
            <div class="qd_subtitle_icon" style="color:red;"> <i class="fas fa-laugh-wink"></i></div>
            {{ $t("quesdom_text4") }}
          </div>
          <div class="p-4"></div>
        </div>
      </div>

      <!--  <div id="private_image_id" class="anim_img">
          <div class="long_live_qd">Long Live Quesdom!</div>
          <img src="@/assets/quesdom_leaves.svg" alt="quesdom" id="svvgg"/>
        </div> -->
    </div>
    <MyFooter />
  </div>
</template>
<script>
//import MyHeader from "@/components/parts/MyHeader.vue";
import MyFooter from "@/components/parts/MyFooter.vue";
import { onBeforeMount } from "vue";
export default {
  components: {
    //  MyHeader,
    MyFooter,
  },
  setup() {
   
    function goToQuesdom(){
    window.location.href='https://quesdom.com';
  }
    onBeforeMount(() => window.scrollTo({ top: 0, behavior: 'smooth' }));
    return {
      goToQuesdom,
    };
  },

  
};
</script>
<style scoped>
.qd_wrapper {
  height: 100%;
}

.qd_column {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  margin-bottom: 1em;
}

.bg7 {
  background-image: url("~@/assets/images/drawing6.svg");
  min-height: 85%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.qd_subtitle {
  font-weight: bolder;
}

.qd_subtitle_icon {
  font-weight: bolder;
  font-size: 2em;
}




#svvgg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}



.qd.card {
  background-color: rgb(255, 255, 255, .8);
  color: black;
  padding: 1em;
}


.skew-on-hover {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.skew_right.skew-on-hover:hover,
.skew-on-hover:focus,
.skew-on-hover:active {
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);
}
.skew_left.skew-on-hover:hover,
.skew-on-hover:focus,
.skew-on-hover:active {
  -webkit-transform: skew(5deg);
  transform: skew(5deg);
}
.link_here{
  color:aqua;
}
.video_div{
  margin:2em;
}

@media only screen and (max-width: 1000px) {
  .qd_column {
    display: block;
    margin-bottom: .5em;
  }

  .anim_img {
    padding-top: 1em;
  }
}
</style>