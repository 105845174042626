<template>
  <div class="bg2 bg">
    <div id="local_start"></div>
    <div id="local_image_div" class="title_image">
      <div>
        <div id="map_image_id" class="anim_img_invisible">
          <img src="@/assets/images/map.png" alt="map" />
        </div>
        <div class="marquee_wrapper">
          <p class="marquee">
            <span
              >&emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
              &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;{{$t("local_scroll_text")}}</span
            >
          </p>
        </div>
      </div>

      <div class="down_arrow" @click="scrollToNext">
        <i class="fas fa-map-marker"></i>
      </div>
      <div class="text_column">
        <h1 class="mg_subtitle">
          {{$t("what_is")}} <span class="mg_title">{{$t("local")}}</span> {{$t("communication")}}
        </h1>
        <div class="mg_description">
          <p>
            {{$t("local_text_1")}}
          </p>
          <p>            {{$t("local_text_2")}}</p>
          <p>
                        {{$t("local_text_3")}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from "vue";
export default {
  setup() {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    function callbackFunc(entries, observer) {
      count += 1;
      if (count == 2) {
        let image = document.getElementById("map_image_id");
        image.classList.remove("anim_img_invisible");
        image.classList.add("anim_img");
      }
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(callbackFunc, options);

    function scrollToNext() {
      let el = document.getElementById("people_start");
      el.scrollIntoView({ behavior: "smooth" });
    }
    onBeforeMount(() => {
      setTimeout(function () {
        let elem = document.getElementById("local_image_div");
        observer.observe(elem);
      }, 100);
    });
    return {
      scrollToNext,
    };
  },
};
</script>
<style scoped>
.bg2 {
  background-image: url("~@/assets/images/drawing2.svg");
  height: 100%;
}
.marquee span {
  animation: marquee 40s linear infinite;
}

.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}
.anim_img_invisible {
  visibility: hidden;
}

/* Make it move */

@keyframes android {
  from {
    left: -150px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 400px) {
  .bg2 {
    display: block;
    overflow: auto;
  }
  .anim_img {
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .text_column {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}
</style>