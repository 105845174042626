<template>
  <div class="dental_wrapper">
    <div id="private_start"></div>
    <MyHeader :with_login="false" style="flex: 1 0 auto" />
    <div class="bg7 bg">
      <div  class="dental_column">
        <div class="text_column">
          <h1 class="mg_title">{{ $t("dental_clinic") }}</h1>
          <div class="mg_description">
            <p>
              {{ $t("dental_clinic_text_1") }}
            </p>
            <br />
            <p>
              {{ $t("dental_clinic_text_2") }}
            </p>
            <p>
              {{ $t("dental_clinic_text_3") }}
            </p>
            <a href="https://www.mindglueapp.com/#/mindgluequest/108" class="hot_link">{{ $t("dental_clinic_example") }}</a>
             
              {{ $t("dental_clinic_text_4") }}
            
             <p>
              {{ $t("dental_clinic_text_5") }}
            </p>
            <a href="mailto:info@mindglue.io" class="hot_link">{{ $t("contact_us") }}</a>
          </div>
        </div>
        <!-- <div id="private_image_id" class="anim_img">
          <img src="@/assets/images/armourMG_smaller.png" alt="android" />
        </div> -->
        <div class="android_img pr-4 ">
          <div style="padding: 75% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/743029656?h=72c1b0ca2f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="mindglue edited-final.mp4"
            ></iframe>
          </div>
          <div style="padding: 75% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/743410977?h=72c1b0ca2f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="mindglue edited-final.mp4"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script>
import MyHeader from "@/components/parts/MyHeader.vue";
import MyFooter from "@/components/parts/MyFooter.vue";
import { onBeforeMount } from "vue";
export default {
  components: {
    MyHeader,
    MyFooter,
  },
  setup() {
    onBeforeMount(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  },
};
</script>
<style scoped>
.dental_wrapper {
  height: 100%;
}
.dental_column {
  display: grid;
  width:100%;
  grid-template-columns: 3fr 2fr;
}
.bg7 {
  background-image: url("~@/assets/images/drawing6.svg");
  min-height: 85%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.anim_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}

@keyframes android {
  from {
    right: -150px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.privacy_link {
  font-weight: 700;
  color: cyan;
}
.privacy_text_column {
  text-align: justify;
}

@media only screen and (max-width: 400px) {
  .privacy_column {
    display: block;
  }
  .anim_img {
    padding-top: 1em;
  }
}
</style>