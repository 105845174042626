<template>
  <div class="bg8 bg">
    <div id="come_start"></div>
    <div class="down_arrow">
      <i class="fas fa-smile-wink" @click="scrollUp"></i>
    </div>
    <div id="come_image_div" class="come_title_image">
      <div id="come_image_id1" class="anim_img_invisible">
        <img src="@/assets/images/balloon.png" alt="balloon" />
      </div>
      <div class="text_column pt-6">
        <h1 class="mg_title">{{$t("come_play")}}</h1>
        <div class="mg_description">
          <p>{{$t("no_big_words_think")}}</p>
          <br />
          <p>
           {{$t("outro_text")}}
          </p>
          <br />
          <p>{{$t("simple_beautiful")}}</p>
        </div>
       <div style="display: flex;  align-items: center; padding-top:1em;">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.mindglue.mindglue"
            >
              <img
                width="250"
                src="~@/assets/images/google-play-badge.png"
                alt=""
              />
            </a>
          </div>
          <div>
            <a
              href="https://apps.apple.com/us/app/mindglue/id1596355198"
            >
              <img
                width="200"
                src="~@/assets/images/app_Store_Badge_US-UK.svg"
                alt=""
              />
            </a>
          </div>
          </div>
      </div>

      <div id="come_image_id2" class="anim_img_invisible">
        <img src="@/assets/images/hot_air_balloon.png" alt="hot air balloon" />
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from "vue";
export default {
  setup() {
    let count = 0;
    // eslint-disable-next-line no-unused-vars
    function callbackFunc(entries, observer) {
      count += 1;
      if (count == 2) {
        let image1 = document.getElementById("come_image_id1");
        let image2 = document.getElementById("come_image_id2");
        image1.classList.remove("anim_img_invisible");
        image2.classList.remove("anim_img_invisible");
        image1.classList.add("anim_img1");
        image2.classList.add("anim_img2");
      }
    }

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    let observer = new IntersectionObserver(callbackFunc, options);

    onBeforeMount(() => {
      setTimeout(function () {
        let elem = document.getElementById("come_image_div");
        observer.observe(elem);
      }, 100);
    });
    function scrollUp() {
      let el = document.getElementById("header_start");
      el.scrollIntoView({ behavior: "smooth" });
    }

    return {
      scrollUp,
    };
  },
};
</script>
<style scoped>
.bg8 {
  background-image: url("~@/assets/images/drawing7.svg");
  height: 95%;
  display: block;
}

.anim_img1 {
  position: relative;
  animation-name: image1anim;
  animation-duration: 3s;
  animation-timing-function: ease;
  padding-left: 1em;
}
.anim_img2 {
  position: relative;
  animation-name: image2anim;
  animation-duration: 3s;
  animation-timing-function: ease;
  padding-right: 1em;
}
.anim_img_invisible {
  visibility: hidden;
}

@keyframes image1anim {
  from {
    left: -150px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

@keyframes image2anim {
  from {
    right: -150px;
    opacity: 0;
  }
  to {
    right: 0px;
    opacity: 1;
  }
}

@keyframes arrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.come_title_image {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
@media only screen and (max-width: 400px) {
  .bg8 {
    display: block;
    overflow: auto;
  }
  .come_title_image {
    display: block;
  }
  .anim_img1 {
    display: none;
  }
  .anim_img2 {
    display: none;
  }
  .anim_img_invisible {
    display: none;
  }
  .text_column {
    padding-bottom: 1em;
    padding-top: 1em;
  }
}
</style>