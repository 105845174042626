<template>
  <router-view></router-view>
</template>

<script>
//https://github.com/vuejs/vuex/blob/4.0/examples/composition/chat/store/actions.js
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  height: 100%;
}
html {
  height: 100%;
    margin: 0;

}
body {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
   
}
</style>
