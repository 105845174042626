<template>
  <div class="bg1 bg">
    <div id="intro_start"></div>

    <div class="title_image">
      <div class="text_column">
        <h1 class="mg_title mt-4">{{ $t("introducing_mindglue") }}</h1>
        <div class="mt-2 ml-4 ,mr-6">
          <iframe :width="frameWidth" :height="frameHeight" src="https://www.youtube.com/embed/v_SF3y9SHvM"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
        <!-- <div class="mg_description">
          <p>
            {{ $t("intro_p_1") }}
            <a href="https://www.mindgluequest.com"
              ><span class="big_link">Mindgluequest</span></a
            >{{ $t("intro_p_2") }}
          </p>
          <p>{{ $t("why_dont_give_it_try") }}</p>
        </div> -->
        <div style="display: flex; align-items: center; padding-top: 1em">
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.mindglue.mindglue">
              <img width="250" src="~@/assets/images/google-play-badge.png" alt="" />
            </a>
          </div>
          <div>
            <a href="https://apps.apple.com/us/app/mindglue/id1596355198">
              <img width="200" src="~@/assets/images/app_Store_Badge_US-UK.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="mg_description">
          {{ $t("create_mindgluequest") }}
          <a href="https://www.mindgluequest.com"><span class="big_link">Mindgluequest</span></a>
          {{ $t("site") }}
        </div>
      </div>
      <div>
        <div class="skew_try">{{ $t("give_it_a_try") }}</div>
        <div class="down_arrow" @click="borderEmphasis">
          <i class="fas fa-chevron-right" style="color: yellow"></i>
        </div>
        <div class="down_arrow pt-4" @click="scrollToNext">
          <i class="fas fa-angle-double-down"></i>
        </div>
      </div>
      <!-- <div class="android_img pr-4">
        <div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/656216353?h=72c1b0ca2f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="mindglue edited-final.mp4"></iframe></div>
      </div> -->
      <div>
        <div id="app_border">
          <iframe src="https://mindglueapp.com" width="340" height="540"></iframe>
        </div>
        <div class="fullscreen">
          <a class="fullscreen_link" href="https://mindglueapp.com">Wanna go fullscreen?</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount,ref } from "vue";
export default {
  setup() {
    onBeforeMount(() => {
      frameHeight.value=315;
      frameWidth.value=560;
      windowWidth.value = window.innerWidth;
      if (windowWidth.value<560){
        frameHeight.value=250;
      frameWidth.value=windowWidth.value;
      }
      //fetch("https://application.mindglue.io/App/mgq/Greeter");
  });

    function scrollToNext() {
      let el = document.getElementById("local_start");
      el.scrollIntoView({ behavior: "smooth" });
    }
    let isWhite = true;
    const frameWidth = ref(0);
    const frameHeight = ref(0);
    const windowWidth=ref(0);
    async function borderEmphasis() {
      if (isWhite) {
        document.getElementById("app_border").style.borderColor = "red";
        isWhite = false;
        setTimeout(borderEmphasis, 500);
      } else {
        document.getElementById("app_border").style.borderColor = "white";
        isWhite = true;
      }
    }
    return {
      frameWidth,
      frameHeight,
      windowWidth,
      scrollToNext,
      borderEmphasis,
      isWhite,
    };
  },
};
</script>
<style scoped>
.bg1 {
  background-image: url("~@/assets/images/drawing.svg");
  height: 90%;
}

.android_img {
  position: relative;
  animation-name: android;
  animation-duration: 3s;
  animation-timing-function: ease;
}

.skew_try {
  font-size: 3em;
  color: yellow;
  /*  transform-origin: 0 0;
  transform: rotate(-20deg); */
}

.fullscreen {
  padding-top: 1em;
}

a.fullscreen_link {
  color: black;
}

#app_border {
  background: #000;
  border-color: white;
  border-width: medium;
  border-style: solid;
  webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 20px;
  width: 340px;
  height: 540px;
  margin: 0 auto;
  overflow: hidden;
  transition: all 1s;
}

@keyframes android {
  from {
    right: -150px;
    opacity: 0;
  }

  to {
    right: 0px;
    opacity: 1;
  }
}

.big_link {
  font-size: 1.5em;
  font-weight: 700;
  color: cyan;
}

@media only screen and (max-width: 400px) {
  .bg1 {
    display: block;
    overflow: auto;
  }

  .android_img {
    padding-bottom: 1em;
  }
}

@media only screen and (max-height: 800px) {
  .fullscreen {
    padding-top: 0.2em;
  }
}
</style>