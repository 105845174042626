<template>
  <div class="my_footer pb-2">
    <div class="inner_footer">
      <div>
        <p style="font-size:1em;"><router-link to="/mindglue_company" style="color:white; font-size:1em;">Mindglue</router-link><span style="font-size:.8em;"> - 2023. All Rights Reserved - </span><router-link to="/privacy" style="color:white; font-weight:bold;"> Privacy Policy</router-link></p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.my_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  background-color: royalblue;
  flex-shrink: 0;
  height: 5%;
}
</style>