<template>
    <div class="page_wrapper">
        <div id="private_start"></div>
      <MyHeader :with_login="false" style="flex: 1 0 auto" />
  
      <div class="bg7 bg">
        <div id="private_image_div" class="page_column">
          
          <div class="text_column">
            <h1 class="mg_title">Delete your content</h1>
            <div class="mg_description">
              <p>
               {{$t("delete_content_text_1")}}
              </p><br>
              <p>
               {{$t("delete_content_text_2")}}
              </p>
            </div>
          </div>
          <div id="private_image_id" class="anim_img">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/yev7ikmLxTo?si=sYUh0Gcw9-VNJL2-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <MyFooter />
    </div>
  </template>
  <script>
  import MyHeader from "@/components/parts/MyHeader.vue";
  import MyFooter from "@/components/parts/MyFooter.vue";
  import { onBeforeMount } from "vue";
  export default {
    components: {
      MyHeader,
      MyFooter,
    },
    setup() {
       /*  function scrollToStart() {
        let el = document.getElementById("private_start");
        el.scrollIntoView({ behavior: "smooth" });
      } */
      onBeforeMount(()=> window.scrollTo({ top: 0, behavior: 'smooth' }));
    },
  };
  </script>
  <style scoped>
  .page_wrapper {
    height: 100%;
  }
  .page_column{
      display: grid;
      grid-template-columns: 3fr 2fr;
  }
  .bg7 {
    background-image: url("~@/assets/images/drawing6.svg");
    min-height: 85%;
    padding-top: 2em;
      padding-bottom: 2em;
  
  }
  
  .anim_img {
    position: relative;
    animation-name: android;
    animation-duration: 3s;
    animation-timing-function: ease;
  }
  
  
  @keyframes android {
    from {
      right: -150px;
      opacity: 0;
    }
    to {
      right: 0px;
      opacity: 1;
    }
  }
  
  @keyframes arrow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .page_link {
    font-weight: 700;
    color: cyan;
  }
  
  
  @media only screen and (max-width: 400px) {
     .page_column{
         display: block;
     }
     .anim_img{
       padding-top:1em;
     }
    }
  </style>